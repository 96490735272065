// Importação correta de módulos nomeados
import firebase from 'firebase/compat/app'; 
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

const FirebaseConfig = {
    apiKey: "AIzaSyDU7Y0AEbymFQ2bC2H4CXcugCnPm4cLOeg",
    authDomain: "intermedio-1513801146022.firebaseapp.com",
    databaseURL: "https://intermedio-1513801146022.firebaseio.com",
    projectId: "intermedio-1513801146022",
    storageBucket: "intermedio-1513801146022.appspot.com",
    messagingSenderId: "534908779124"
};

const app = firebase.initializeApp(FirebaseConfig);

export const storage = firebase.storage();
export const firestore = firebase.firestore();

export default app;
