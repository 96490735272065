import { id_firebase } from './content-site';
import { firestore } from './firebase-config';
import { useEffect } from 'react';

export default function JSONResponse({ history }) {

    useEffect(() => {
        firestore.collection("revenda").doc(id_firebase).collection("veiculos")
            .where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .limit(1)
            .get()
            .then(querySnapshot => {
                let items = [];
                querySnapshot.forEach(doc => {
                    let document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });

                // Função para gerar a estrutura JSON
                function gerarJSON(allVehicles) {
                    return allVehicles.map(item => ({
                        idStore: item.created_id,
                        plate: item.veiplaca,
                        description: {
                            brand: item.vefipe_marca,
                            model: item.vefipe_name,
                            km: item.vequilometragem,
                            fabricationYear: item.veano_fabricacao,
                            modelYear: item.vefipe_ano_modelo,
                            fuel: item.vefipe_combustivel,
                            gearbox: item.vecambio,
                            color: item.vecor,
                            bodywork: item.vecarroceria,
                            doors: item.veportas
                        },
                        type: ['carro', 'moto', 'caminhao', 'outros'].includes(item.vetipoveiculo) ? item.vetipoveiculo : null,
                        opcionais: item.opcionais ? item.opcionais.map(opcional => opcional.descricao) : [],
                        fipeId: "",
                        armored: item.veblindado === false ? "Não" : "Sim",
                        model: item.ve_modelo || "",
                        version: item.ve_versao || "",
                        link: `https://edcarveiculos.com.br/#/${item.id}`,
                        images: item.imagens ? item.imagens.map(imagem => imagem.fileUrl) : [],
                        condition: item.veveiculonovo === 1 ? 'new' : 'used',
                        availability: item.vevendido === 0 ? 'in stock' : 'out of stock',
                        price: `${item.vevalor} BRL`,
                        brand: item.vefipe_marca || ""
                    }));
                }

                const jsonString = JSON.stringify(gerarJSON(items), null, 2);

                // Defina o tipo de resposta como JSON
                const blob = new Blob([jsonString], { type: "application/json" });
                const url = URL.createObjectURL(blob);
                window.location.href = url; // Redireciona para o link de download do JSON
            });
    }, [history]);

    return null; // Não renderiza nada na tela
}
